<template>
  <div class="configurator h-100 container-fluid">
    <div class="section-container h-100">
      <!-- parte destra -->
      <div class="row h-100">
        <div class="col-md-12 h-100" id="left-side">
          <img class="ceasy-logo img-fluid" src="@/assets/logo_customeasy_oriz.png" />

          <div class="row h-100 justify-content-center align-items-center">
            <div class="col d-flex flex-wrap justify-content-center align-items-center">
              <ErrorMessage :message="$t('PAGE_NOT_FOUND')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage.vue'
export default {
  name: 'notFound',
  components: {
    ErrorMessage
  }
}
</script>

<style scoped lang="scss">
.configurator {
  background-color: $c-white;
  overflow: hidden;

  .ceasy-logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 50%;
    max-width: 350px;
  }
}
</style>
